import React, { useState, useEffect } from 'react'
import ConfettiExplosion from 'react-confetti-explosion';
import { useInView } from "react-intersection-observer";

const Conclusion = ({}) => {
  const [ exploding, setExploding ] = useState(false)
  const { ref, inView, entry } = useInView();

  useEffect(() => {
    let timeout = null

    if (inView) {
      timeout = setTimeout(() => {
        setExploding(true)
      }, 500)
    }
    else {
      setExploding(false)
      clearTimeout(timeout)
    }

    return () => {
      setExploding(false)
      clearTimeout(timeout)
    }
  }, [inView])

  return (
    <>
      { exploding && 
        <div className="confetti-container">
          <ConfettiExplosion />
        </div>
      }
      <div className="year-in-review-slide" ref={ref}>
        <div className="year-in-review-slide-title">Happy New Year!</div>
        <div className="year-in-review-slide-info mt-3">Congratulations on a great 2024 and looking forward to an even better 2025!</div>
        <div className="mt-5">
          <div className="year-in-review-slide-info">For more statistics and end of year reports visit Sales -> Reports</div>
        </div>

        <div className="mt-5">
          <div className="year-in-review-slide-info"><a href="/dashboard">Go to Dashboard</a></div>
        </div>
      </div>
    </>
  )
}

export default Conclusion
