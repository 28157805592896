import React from 'react'

const TotalRevenueSlide = ({ totalPayments, totalPaymentsYearOverYearAmount, totalPaymentsYearOverYearPercent }) => (
  <div className="year-in-review-slide">
    <div className="year-in-review-slide-intro">Total Revenue Collected</div>
    <div className="year-in-review-slide-title">{ totalPayments }</div>
    <div className="year-in-review-slide-info">{ totalPaymentsYearOverYearAmount }</div>
    <div className="year-in-review-slide-info">{ totalPaymentsYearOverYearPercent }</div>
  </div>
)

export default TotalRevenueSlide
