import React from 'react'

const AddOnsByFrequency = ({ addOnsByFrequency }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-info mb-5">Most Popular Add-Ons</div>
      { addOnsByFrequency.map((currentAddOn, index) => (
        <div key={index}>
          <div className="year-in-review-slide-intro">#{index + 1}: { currentAddOn.name}</div>
          <div className="year-in-review-slide-info">{ currentAddOn.bookingCountDescription }</div>
          <hr />
        </div>
      ))}
    </div>
  )
}

export default AddOnsByFrequency
