import React from 'react'

const BackdropsByRevenue = ({ backdropsByRevenue }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-info mb-5">Highest Revenue Backdrops</div>
      { backdropsByRevenue.map((currentBackdrop, index) => (
        <div key={index}>
          <div className="year-in-review-slide-intro">#{index + 1}: { currentBackdrop.name}: { currentBackdrop.revenue }</div>
          <div className="year-in-review-slide-info">{ currentBackdrop.bookingCountDescription }</div>
          <div className="year-in-review-slide-info">{ currentBackdrop.percentRevenueDescription }</div>
          <hr />
        </div>
      ))}
    </div>
  )
}

export default BackdropsByRevenue
