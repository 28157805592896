import React from 'react'

const ProposalsAccepted = ({ currentAcceptedProposalCount, acceptedProposalCountYearOverYearPercent }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-title">{ currentAcceptedProposalCount } proposals were accepted</div>
      <div className="year-in-review-slide-info">{ acceptedProposalCountYearOverYearPercent }</div>
    </div>
  )
}

export default ProposalsAccepted
