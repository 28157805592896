import React from 'react'

const PackagesByRevenue = ({ packagesByRevenue }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-info mb-5">Highest Revenue Packages</div>
      { packagesByRevenue.map((currentPackage, index) => (
        <div key={index}>
          <div className="year-in-review-slide-intro">#{index + 1}: { currentPackage.name}: { currentPackage.revenue }</div>
          {/* <div className="year-in-review-slide-info">{ currentPackage.bookingCountDescription }</div> */}
          <div className="year-in-review-slide-info">{ currentPackage.percentRevenueDescription }</div>
          <hr />
        </div>
      ))}
    </div>
  )
}

export default PackagesByRevenue
