import React from 'react'

const PackagesByFrequency = ({ packagesByFrequency }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-info mb-5">Most Booked Packages</div>
      { packagesByFrequency.map((currentPackage, index) => (
        <div key={index}>
          <div className="year-in-review-slide-intro">#{index + 1}: { currentPackage.name}: { currentPackage.bookingCountDescription }</div>
          <div className="year-in-review-slide-info">{ currentPackage.revenue }</div>
          <div className="year-in-review-slide-info">{ currentPackage.percentFrequencyDescription }</div>
          <hr />
        </div>
      ))}
    </div>
  )
}

export default PackagesByFrequency
