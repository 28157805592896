import React from 'react'

const AddOnsByRevenue = ({ addOnsByRevenue }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-info mb-5">Highest Revenue Add Ons</div>
      { addOnsByRevenue.map((currentAddOn, index) => (
        <div key={index}>
          <div className="year-in-review-slide-intro">#{index + 1}: { currentAddOn.name}: { currentAddOn.revenue }</div>
          <div className="year-in-review-slide-info">{ currentAddOn.bookingCountDescription }</div>
          <div className="year-in-review-slide-info">{ currentAddOn.percentRevenueDescription }</div>
          <hr />
        </div>
      ))}
    </div>
  )
}

export default AddOnsByRevenue
