import React from 'react'

const MessageCounts = ({ totalSentEmailCount, automaticallySentEmailCount, manuallySentEmailCount }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-title">{ totalSentEmailCount } emails were sent</div>
      <div className="year-in-review-slide-info">{ automaticallySentEmailCount } were sent automatically</div>
      <div className="year-in-review-slide-info">{ manuallySentEmailCount } were sent manually</div>
    </div>
  )
}

export default MessageCounts
