import { Controller } from "stimulus"
import { dynamicContentLoaded } from '../../helpers/dynamic-content'
import { showTarget, hideTarget } from '../../helpers/visibility'
import packageMetaFor from '../../helpers/package-meta'
import normalize from 'json-api-normalizer'

export default class extends Controller {
  static targets = [
    "packageSelector",
    "packageForm",
    "existingCustomerWrapper",
    "newContactWrapper",
    "contactType",
    "bookingConfirmationWrapper",
    "addPackageTemplate",
    "addPackageButtonWrapper",
    "removePackageButton"
  ]

  static values = {
    packageMeta: Object
  }

  connect() {
    const normalized = normalize(this.packageMetaValue)
    this.packageMeta = normalized.eventType
    this.packageUnitRangeMeta = normalized.packageUnitRange
    this.updateVisibility()
  }

  updateVisibility() {
    this.packageSelectorTargets.forEach(elem => {
      console.log({ elem })
      const packageId = elem.value
      const selectedPackageMeta = packageMetaFor({ packageMeta: this.packageMeta, packageUnitRangeMeta: this.packageUnitRangeMeta, packageId })
      const customEditorTarget = elem.closest('.quick-event-booking').querySelector(".custom-fields")
      const backdropTarget = elem.closest('.quick-event-booking').querySelector('.backdrop-fields')
      const extraHoursTarget = elem.closest('.quick-event-booking').querySelector('.extra-hours-fields')
      const startTimeTarget = elem.closest('.quick-event-booking').querySelector('.start-time-field')
      const extraDaysTarget = elem.closest('.quick-event-booking').querySelector('.extra-days-fields')
      const unitsTarget = elem.closest('.quick-event-booking').querySelector('.units-fields')
      const unitRangesTarget = elem.closest('.quick-event-booking').querySelector('.unit-ranges-fields')

      if(selectedPackageMeta.dateMode === "date_only") {
        hideTarget(startTimeTarget)
      }
      else {
        showTarget(startTimeTarget)
      }

      if(selectedPackageMeta.hasBackdrops) {
        showTarget(backdropTarget)
      }
      else {
        hideTarget(backdropTarget)
      }

      if(selectedPackageMeta.enableCustomerCanBookExtraHours) {
        showTarget(extraHoursTarget)
      }
      else {
        hideTarget(extraHoursTarget)
      }

      if(selectedPackageMeta.enableCustomerCanBookExtraDays) {
        showTarget(extraDaysTarget)
      }
      else {
        hideTarget(extraDaysTarget)
      }

      if(selectedPackageMeta.enableCustomerCanBookPerUnit) {
        showTarget(unitsTarget)
        unitsTarget.querySelector(".form-label").innerText = selectedPackageMeta.unitsTitle
      }
      else {
        hideTarget(unitsTarget)
      }

      if(selectedPackageMeta.enableCustomerCanBookPerUnitRange) {
        showTarget(unitRangesTarget)
        unitRangesTarget.querySelector(".form-label").innerText = selectedPackageMeta.unitsTitle
        const html = selectedPackageMeta.packageUnitRanges
          .sort((a, b) => a.maxUnits - b.maxUnits)
          .sort((a, b) => a.minUnits - b.minUnits)
          .map(r => `<option value="${ r.id }">${ r.unitRangeLabel }</option>`)
          .join("\n")
        unitRangesTarget.querySelector('select').innerHTML = html
      }
      else {
        hideTarget(unitRangesTarget)
      }

      console.log({ packageId })
      if(packageId === "custom") {
        showTarget(customEditorTarget)
      }
      else {
        hideTarget(customEditorTarget)
      }
    })


    const contactType = this.contactTypeTargets.find(input => input.checked).value
    if(contactType === "New Customer") {
      showTarget(this.newContactWrapperTarget)
      hideTarget(this.existingCustomerWrapperTarget)
      showTarget(this.bookingConfirmationWrapperTarget)
    }
    else if(contactType == "Existing Customer") {
      hideTarget(this.newContactWrapperTarget)
      showTarget(this.existingCustomerWrapperTarget)
      showTarget(this.bookingConfirmationWrapperTarget)
    }
    else {
      hideTarget(this.newContactWrapperTarget)
      hideTarget(this.existingCustomerWrapperTarget)
      hideTarget(this.bookingConfirmationWrapperTarget)
    }

    if(this.removePackageButtonTargets.length > 1) {
      this.removePackageButtonTargets.forEach(button => showTarget(button))
    }
    else {
      this.removePackageButtonTargets.forEach(button => hideTarget(button))
    }
  }

  addPackage(e) {
    e.preventDefault()

    const newUuid = new Date().getTime()
    const content = this.addPackageTemplateTarget.innerHTML.replace(/NEW_RECORD/g, newUuid)
    this.addPackageButtonWrapperTarget.insertAdjacentHTML('beforebegin', content)
    dynamicContentLoaded()
    this.updateVisibility()
  }

  removePackage(e) {
    e.preventDefault()
    e.target.closest('.quick-event-booking').remove()
    this.updateVisibility()
  }
}
