import React from 'react'

const LeadCounts = ({ currentLeadCount, leadCountYearOverYearPercent }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-title">{ currentLeadCount } leads were collected</div>
      <div className="year-in-review-slide-info">{ leadCountYearOverYearPercent }</div>
    </div>
  )
}

export default LeadCounts
