import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import TotalRevenueSlide from './components/TotalRevenueSlide'
import PackagesByRevenue from './components/PackagesByRevenue'
import PackagesByFrequency from './components/PackagesByFrequency'
import AddOnsByRevenue from './components/AddOnsByRevenue'
import AddOnsByFrequency from './components/AddOnsByFrequency'
import BackdropsByRevenue from './components/BackdropsByRevenue'
import BackdropsByFrequency from './components/BackdropsByFrequency'
import LeadCounts from './components/LeadCounts'
import MessageCounts from './components/MessageCounts'
import ProposalsAccepted from './components/ProposalsAccepted'
import ProposalsCreated from './components/ProposalsCreated'
import Conclusion from './components/Conclusion'
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import "./style.scss"

const YearInReview = (props) => {
  const slides = [
    {
      component: TotalRevenueSlide,
      className: "slide-wrapper-total-revenue",
      visible: true,
    },
    {
      component: PackagesByFrequency,
      className: "slide-wrapper-packages-by-frequency",
      visible: props.packagesByFrequency.length > 0
    },
    {
      component: PackagesByRevenue,
      className: "slide-wrapper-packages-by-revenue",
      visible: props.packagesByRevenue.length > 0
    },
    {
      component: AddOnsByFrequency,
      className: "slide-wrapper-add-ons-by-frequency",
      visible: props.addOnsByFrequency.length > 0
    },
    {
      component: AddOnsByRevenue,
      className: "slide-wrapper-add-ons-by-revenue",
      visible: props.addOnsByRevenue.length > 0
    },
    {
      component: BackdropsByFrequency,
      className: "slide-wrapper-backdrops-by-frequency",
      visible: props.backdropsByFrequency.length > 0
    },
    {
      component: BackdropsByRevenue,
      className: "slide-wrapper-backdrops-by-revenue",
      visible: props.backdropsByRevenue.length > 0
    },
    {
      component: ProposalsCreated,
      className: "slide-wrapper-proposals-created",
      visible: props.currentTotalProposalCount > 0
    },
    {
      component: ProposalsAccepted,
      className: "slide-wrapper-proposals-accepted",
      visible: props.currentAcceptedProposalCount > 0
    },
    {
      component: LeadCounts,
      className: "slide-wrapper-leads-counts",
      visible: props.currentLeadCount > 0
    },
    {
      component: MessageCounts,
      className: "slide-wrapper-message-counts",
      visible: props.totalSentEmailCount > 0
    },
    {
      component: Conclusion,
      className: "slide-wrapper-conclusion",
      visible: true
    },
  ].filter(slide => slide.visible)
  console.log({ slides })

  return (
    <div className="year-in-review-widget">
      <AwesomeSlider
        fillParent
        animation="foldOutAnimation"
        infinite={false}
      >
        { slides.map((slide, index) => {
          const SlideComponent = slide.component
          return (
            <div key={index} className={`slide-wrapper ${slide.className}`}>
              <SlideComponent {...props} />
            </div>
          )
        }
        )}
      </AwesomeSlider>
    </div>
  )
}

export default YearInReview

