import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Input from './Input'
import HintField from '../HintField'
import 'react-datepicker/dist/react-datepicker.css';
import './style.css'


export default class DateField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    dateFormat: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    style: PropTypes.object,
    hint: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
    labelsAsPlaceholders: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    className: PropTypes.string
  }

  static defaultProps = {
    labelsAsPlaceholders: false,
    labelStyle: {},
    inputStyle: {},
    className: ''
  }

  get errorMessage() {
    const { errors, name } = this.props

    if(errors && errors[name]) {
      return errors[name][0]
    }
    return null
  }

  get helpMessage() {
    const { hint } = this.props
    return this.errorMessage || hint || null
  }

  render() {
    const { name, className, label, value, onChange, hint, style, errors, required, dateFormat, labelsAsPlaceholders, labelStyle, inputStyle, minDate, maxDate } = this.props
    const {  helpMessage } = this

    return (
      <FormGroup
        style={{...styles.wrapper, ...style}}
        className={`checkcherry__widget__datepicker ${className} ${name}`}>
        { (!labelsAsPlaceholders) &&
          <Form.Label style={{...styles.label, ...labelStyle}}>
            { label }
            { required && <span style={styles.required}>*</span> }
          </Form.Label>
        }
        <DatePicker
          name={name}
          customInput={<Input style={{...styles.input, ...inputStyle}} />}
          onChange={onChange}
          selected={value}
          dateFormat={dateFormat}
          placeholderText={labelsAsPlaceholders ? label : null}
          minDate={minDate}
          maxDate={maxDate}
          isInvalid={!!this.errorMessage}
        />
        <HintField
          hint={hint}
          errorMessage={this.errorMessage}
        />
      </FormGroup>
    )
  }
}

const styles = {
  label: {
    fontSize: 18,
  },
  input: {
    fontSize: 18
  },
  required: {
    color: "#cc0000",
    fontWeight: "normal",
    paddingLeft: 2
  }
}
