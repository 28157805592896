import React from 'react'

const BackdropsByFrequency = ({ backdropsByFrequency }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-info mb-5">Most Popular Backdrops</div>
      { backdropsByFrequency.map((currentBackdrop, index) => (
        <div key={index}>
          <div className="year-in-review-slide-intro">#{index + 1}: { currentBackdrop.name}</div>
          <div className="year-in-review-slide-info">{ currentBackdrop.bookingCountDescription }</div>
          <hr />
        </div>
      ))}
    </div>
  )
}

export default BackdropsByFrequency
