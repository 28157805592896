import React from 'react'

const ProposalsCreated = ({ currentTotalProposalCount, totalProposalCountYearOverYearPercent }) => {
  return (
    <div className="year-in-review-slide">
      <div className="year-in-review-slide-title">{ currentTotalProposalCount } proposals were created</div>
      <div className="year-in-review-slide-info">{ totalProposalCountYearOverYearPercent }</div>
    </div>
  )
}

export default ProposalsCreated
